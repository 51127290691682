export const data_dummy = {
  mainService: [
    {
      image: 'http://transporter.saranapatra.com/images/padat.png',
      name: 'Limbah B3 Padat',
      content: 'Kami melayani pengangkutan limbah B3 padat berupa komponen-komponen elektronik seperti lampu, PCB dan baterai bekas, serta benda-benda berbahan gelas, plastik, kayu dan logam'
    },
    {
      image: 'http://transporter.saranapatra.com/images/cair.png',
      name: 'Limbah B3 Cair',
      content: 'Kami melayani pengangkutan limbah B3 cair berupa tinta, oli, cat, perekat, pelarut, bahan atau produk,  produk pembersih, desinfektan, farmasi, serta berbagai jenis bahan kimia lain nya.'
    },
    {
      image: 'http://transporter.saranapatra.com/images/residu.png',
      name: 'Limbah B3 Residu',
      content: 'Kami melayani pengangkutan limbah B3 dalam bentuk serpihan, debu dan endapan seperti residu proses produksi, debu emisi, debu tumpahan bahan produk/katalis bekas'
    },
  ],
  kodeLimbah: [
    {
      "title": "A102d",
      "name": "Aki/baterai bekas"
    },
    {
      "title": "A107d",
      "name": "Pelarutan bekas lainnya yang blm dikodefikasi"
    },
    {
      "title": "A307-1",
      "name": "Sludge dari proses produksi dan fasilitas penyimpanan minyak bumi atau gas alam (kilang minyak gas Bumi)"
    },
    {
      "title": "A325-1",
      "name": "Limbah Cat san varnish mengandung pelarut organik (cat)"
    },
    {
      "title": "A327-3",
      "name": "Sludge proses produksi (baterai sel basah)"
    },
    {
      "title": "A330-1",
      "name": "Residu dasar tangki minyak bumi (eksplorasi dan produksi minyak, gas, dan panas bumi)"
    },
    {
      "title": "A330-2",
      "name": "Residu proses produksi"
    },
    {
      "title": "A331-2",
      "name": "Sludge dari oil treatment atau fasilitas penyimpanan (pertambangan)"
    },
    {
      "title": "A336-1",
      "name": "Bahan atau produk yang tidak memenuhi spesifikasi teknis, kadaluarsa dan sisa"
    },
    {
      "title": "A336-2",
      "name": "Residu proses produksi dan formulasi"
    },
    {
      "title": "A336-3",
      "name": "Residu proses evaporasi dan reaksi"
    },
    {
      "title": "A336-4",
      "name": "Reactor bottom wastes"
    },
    {
      "title": "A336-5",
      "name": "Sluge dari Fasilitas produksi"
    },
    {
      "title": "A337-1",
      "name": "Limbah klinis memiliki karakteristik infeksius"
    },
    {
      "title": "A337-2",
      "name": "Produk Farmasi kedaluwarsa"
    },
    {
      "title": "A337-4",
      "name": "Peralatan Laboratorium terkontaminasi B3"
    },
    {
      "title": "A337-5",
      "name": "Peralatan medis mengandung logam berat,termasuk merkuri (Hg),cadmium(Cd),dan sejenisnya"
    },
    {
      "title": "A338-1",
      "name": "Bahan kimia kedaluwarsa"
    },
    {
      "title": "A338-2",
      "name": "Peralatan laboratorium terkontaminasi B3"
    },
    {
      "title": "A338-3",
      "name": "Residu sampel limbah B3"
    },
    {
      "title": "A338-4",
      "name": "Sludge IPAL"
    },
    {
      "title": "A339-1",
      "name": "Larutan developer,fixer, dan bleach bekas (fotografi)"
    },
    {
      "title": "B104d",
      "name": "Kemasan bekas B3"
    },
    {
      "title": "B105d",
      "name": "Minyak pelumasan bekas antara lain minyak pelumas bekas hidrolik, mesin gear, lubrikasi, insulasi, heat transmission, grit chanbers, separator dan atau campurannya"
    },
    {
      "title": "B107d",
      "name": "Limbah elektronik termasuk cathode ray tube (CRT), lampu TL, Printed circuit board (PCB), karet kawat (wire rubber)"
    },
    {
      "title": "B110d",
      "name": "Kain majun bekas (used rags) dan yang sejenisnya"
    },
    {
      "title": "B321-2",
      "name": "Sludge tinta (tinta)"
    },
    {
      "title": "B321-4",
      "name": "Kemasan bekas tinta"
    },
    {
      "title": "B323-1",
      "name": "Sisa proses blasting (manufaktur, perakitan, dan pemeliharaan kendaraan dan mesin)"
    },
    {
      "title": "B328-4",
      "name": "Printed circuit board (PCB) perakitan komponen elektronik atau peralatan elektronik)"
    },
    {
      "title": "B328-5",
      "name": "Limbah kabel logam & insulasinya"
    },
    {
      "title": "B330-1",
      "name": "Limbah lumpur bor berbahan dasar oil base dan / atau synthetic oil (eksplorasi dan produksi minyak, gas dan panas bumi)"
    },
    {
      "title": "B333-1",
      "name": "Debu dari fasilitas pengendalian pencemaran udara selain Limbah dengan kode limbah B409 atau B410 (pembangkit listrik tenaga uap PLTU)"
    },
    {
      "title": "B336-1",
      "name": "Absorben dan filter bekas atau karbon aktif"
    },
    {
      "title": "B337-1",
      "name": "Kemasan Produk farmasi"
    },
    {
      "title": "B339-2",
      "name": "Tinta, tonner (fotografi)"
    },
    {
      "title": "B347-1",
      "name": "Residu pengolahan flue gas (pengoperasian insinerator limbah)"
    },
    {
      "title": "B347-3",
      "name": "Sluge IPAL"
    },
    {
      "title": "B405",
      "name": "Iron concentrate"
    },
    {
      "title": "B406",
      "name": "Mill scale"
    },
    {
      "title": "B407",
      "name": "Debu EAF"
    },
    {
      "title": "B409",
      "name": "Fly ash"
    },
    {
      "title": "B410",
      "name": "Bottom ash"
    },
    {
      "title": "B414",
      "name": "Gipsum (PLTU, industri pupuk, MSG)"
    }
  ],
  dataOurClient: [
    {
      name: 'col-1',
      data: [
        {},
        {},
        {},
      ]
    },
    {
      name: 'col-2',
      data: [
        {},
        {},
        {},
      ]
    },
    {
      name: 'col-3',
      data: [
        {},
        {},
        {},
      ]
    },
    {
      name: 'col-4',
      data: [
        {},
        {},
        {},
      ]
    },
    {
      name: 'col-5',
      data: [
        {},
        {},
        {},
      ]
    },
    {
      name: 'col-6',
      data: [
        {},
        {},
        {},
      ]
    },
  ],
  galeri_transporter: {
    types: [
      {
        type: 'Armada'
      },
      {
        type: 'Pengangkutan'
      }
    ],
    data: [
      {
        name: 'http://retester.saranapatra.com/images/retester_11.jpg.jpg',
        type: 'Retester'
      },
      {
        name: 'http://retester.saranapatra.com/images/retester_22.jpg.jpg',
        type: 'Retester'
      },
      {
        name: 'http://retester.saranapatra.com/images/retester_33.jpg.jpg',
        type: 'Retester'
      },
      {
        name: 'http://retester.saranapatra.com/images/retester_44.jpg.jpg',
        type: 'Retester'
      },
      {
        name: 'http://retester.saranapatra.com/images/retester_55.jpg.jpg',
        type: 'Retester'
      },
      {
        name: 'http://retester.saranapatra.com/images/retester_66.jpg.jpg',
        type: 'Retester'
      },
      {
        name: 'http://retester.saranapatra.com/images/retester_77.jpg.jpg',
        type: 'Retester'
      },
    ]
  }
}
